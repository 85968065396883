@mixin app-sale-linkedcards-block {
  app-sale-linkedcards-block {
    display: inline-flex; // его размеры отдаём в iframe
    flex-wrap: nowrap;
    margin: 0.2rem;
    gap: pxtorem(20);

    .vgs-form {
      display: flex;
      flex-direction: column;
      // height: pxtorem(96);
      // justify-content: space-between;
      @include transition;
    }

    .form-field {
      display: block;
      width: pxtorem(75);
      overflow: hidden;
      height: pxtorem(58);

      iframe {
        height: 100%;
        width: 100vw;
      }
    }

    label {
      color: var(--pp-dark-gray);
      font-size: 1.2rem;
      line-height: 1.4;
    }

    .err-msg {
      background-color: var(--pp-coral);
      padding-left: 0.5rem;
      border-radius: 0.3125rem;
      padding-top: 0.5rem;
      padding-bottom: 0.4rem;
      color: var(--pp-white);
      font-size: 0.75rem;
      font-weight: bold;
      width: pxtorem(75);
      margin-top: pxtorem(-5);
      z-index: -1;
      word-break: break-word;
    }

    .bankcards-block {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .row {
      cursor: pointer;
      border-radius: pxtorem(4);
      border: pxtorem(1) solid rgba(10, 10, 10, 0.1);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      background-color: var(--pp-light-gray);
      padding: 0.5rem;
      height: pxtorem(58);
      @include transition;

      &:hover,
      &.checked {
        background-color: transparent;
      }

      &.checked {

        .radio-block,
        .img-block,
        .info-block {
          pointer-events: none;
        }
      }

      h5 {
        @include h5-regular-medium-gray;
      }

      .radio-block {
        display: flex;
        align-items: center;

        input {
          width: 1rem;
        }
      }

      .img-block {
        display: flex;
        width: pxtorem(60);
        background-position: center;
        background-repeat: no-repeat;

        &.MASTERCARD {
          background-image: url(#{$url-content-getimage}/IMG.PROVIDER.MASTERCARD);
        }

        &.VISA {
          background-image: url(#{$url-content-getimage}/IMG.PROVIDER.VISA);
        }
      }

      .info-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: pxtorem(230);
        text-align: right;
        padding: 0.5rem 0;
      }

      .action-block {
        background-position: center -0.2rem;
        background-repeat: no-repeat;
        background-size: 60%;
        background-image: url(#{$url-content-getimage}/IMG.TRASH.CAN);
        padding: 0 2rem;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    app-sale-linkedcards-block {
      .row {
        .info-block {
          width: pxtorem(140);
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    app-sale-linkedcards-block {
      .row {
        .info-block {
          width: pxtorem(140);
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    app-sale-linkedcards-block {
      display: flex;
      flex-wrap: wrap;

      label {
        font-size: 1rem;
      }

      form {
        flex-basis: 100%;
        height: auto;
      }

      .vgs-form {
        flex-basis: 100%;
        height: pxtorem(101);
      }

      .row {
        flex-wrap: nowrap;
        height: auto;

        &.checked {
          margin-bottom: pxtorem(100);
        }

        // .info-block {
        // }
        .action-block {

          background-position: center -0.1rem;
        }
      }

      .err-msg {
        background-color: transparent;
        color: var(--pp-coral);
        font-family: "Circe Light";
      }
    }
  }
}