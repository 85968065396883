@mixin custom-text-input() {
  display: block;
  width: pxtorem(356);
  padding: pxtorem(6) pxtorem(12);
  font-size: pxtorem(16);
  font-weight: 400;
  line-height: pxtorem(24);
  color: $black;
  background-color: var(--pp-body-bg);
  background-clip: padding-box;
  border: pxtorem(1) solid var(--pp-gray-400);
  -webkit-appearance: none;
  appearance: none;
  border-radius: pxtorem(4);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #2d3947;
    background-color: var(--bs-body-bg);
    border-color: #7abece;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(122, 190, 206, 0.25);
  }
  background-image: none;
}

@mixin custom-file-input() {
  @include custom-text-input();
  &::file-selector-button {
    padding: $input-padding-y $input-padding-x;
    margin: (-$input-padding-y) (-$input-padding-x);
    margin-inline-end: $input-padding-x;
    color: $form-file-button-color;
    @include gradient-bg($form-file-button-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: $input-border-width;
    border-radius: 0; // stylelint-disable-line property-disallowed-list
    @include transition($btn-transition);
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: $form-file-button-hover-bg;
  }
}

@mixin button-variant(
  $background,
  $border,
  $color,
  $hover-background,
  $hover-border,
  $hover-color,
  $active-background,
  $active-border,
  $active-color,
  $disabled-background,
  $disabled-border,
  $disabled-color,
  // $box-shadow
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-bg: #{$background};
  --#{$prefix}btn-border-color: #{$border};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  // --#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix($color, $border, 15%))};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  // --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$disabled-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};
  // --#{$prefix}btn-box-shadow: #{$box-shadow};
  box-shadow: var(--pp-box-shadow);
  --pp-btn-font-family: var(--pp-Inter-Regular);
}

@mixin button-outline-variant(
  $color,
  $color-hover,
  $active-background: $color,
  $active-border: $color,
  $active-color
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: #{$color};
  --#{$prefix}btn-hover-color: #{$color-hover};
  --#{$prefix}btn-hover-bg: #{$active-background};
  --#{$prefix}btn-hover-border-color: #{$active-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$color};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: #{$color};
  --#{$prefix}gradient: none;
  box-shadow: var(--pp-box-shadow);
}

@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  --#{$prefix}btn-padding-y: #{$padding-y};
  --#{$prefix}btn-padding-x: #{$padding-x};
  @include rfs($font-size, --#{$prefix}btn-font-size);
  --#{$prefix}btn-border-radius: #{$border-radius};
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin custom-header($font-size, $font-family, $color, $transition: null) {
  font-size: pxtorem($font-size);
  font-family: $font-family;
  color: $color;
  @if ($transition != null) {
    @include transition($transition);
  }
}
/////////////////// H1 /////////////////////////////////////////////////////////
@mixin h1-regular-blue {
  @include custom-header(36, var(--pp-Inter-Regular), var(--pp-blue-1));
}

@mixin h1-regular-gray-0-72 {
  @include custom-header(
    36,
    var(--pp-Inter-Regular),
    var(--pp-gray-opacity-0-72)
  );
}

/////////////////// H5 /////////////////////////////////////////////////////////

@mixin h5-regular-blue {
  @include custom-header(
    18,
    var(--pp-Circe-Regular),
    var(--pp-blue-1),
    $transition-base
  );
}

@mixin h5-regular-gray-0-72 {
  @include custom-header(
    18,
    var(--pp-Circe-Regular),
    var(--pp-gray-opacity-0-72),
    $transition-base
  );
}

@mixin h5-regular-medium-gray {
  @include custom-header(
    18,
    var(--pp-Circe-Regular),
    var(--pp-medium-gray),
    $transition-base
  );
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///
@mixin badge() {
  padding: pxtorem(4) pxtorem(8);
  font-size: pxtorem(14);
  line-height: pxtorem(20);
  border-radius: pxtorem(8);
  font-family: var(--pp-Inter-Medium);
  text-align: center;
}

///

@mixin custom-scrollbar(
  $width: pxtorem(16),
  $height: pxtorem(16),
  $border-radius: pxtorem(16)
) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--pp-gray-6);
    box-shadow: inset 0 0 4px var(--pp-gray-opacity-0-12);
    border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: $border-radius;
    border-radius: $border-radius;
    background-color: var(--pp-gray-opacity-0-12);
  }

  &::-webkit-scrollbar-corner {
    opacity: 0;
  }
}

@mixin popover-variant(
  $zindex-popover,
  $popover-max-width,
  $popover-body-padding-x,
  $popover-body-padding-y,
  $popover-font-size,
  $popover-arrow-border,
  $popover-arrow-width,
  $popover-arrow-height,
  $popover-body-color,
  $popover-border-width,
  $popover-border-radius
) {
  --#{$prefix}popover-zindex: #{$zindex-popover};
  --#{$prefix}popover-max-width: #{$popover-max-width};
  --#{$prefix}popover-body-padding-x: #{$popover-body-padding-x};
  --#{$prefix}popover-body-padding-y: #{$popover-body-padding-y};
  --#{$prefix}popover-font-size: #{$popover-font-size};
  --#{$prefix}popover-arrow-border: #{$popover-arrow-border};
  --#{$prefix}popover-arrow-width: #{$popover-arrow-width};
  --#{$prefix}popover-arrow-height: #{$popover-arrow-height};
  --#{$prefix}popover-body-color: #{$popover-body-color};
  --#{$prefix}popover-border-width: #{$popover-border-width};
  --#{$prefix}popover-border-radius: #{$popover-border-radius};
}
