//Clean up all maps
$colors: map-remove(
  $colors,
  "blue",
  "indigo",
  "purple",
  "pink",
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "cyan",
  "black",
  "white",
  "gray",
  "gray-dark"
);

//My Maps
//------------------------------------------------------------------------------------//

$custom-colors: (
  "light-gray": #f3f3f3,
  "medium-gray": #91989f,
  "dark-gray": #46505b,
  "coral": #ec6665,
  "white": white,
);
$colors: map-merge($colors, $custom-colors);

$theme-colors: map-remove(
  $theme-colors,
  "primary",
  "secondary",
  "success",
  "info",
  "warning",
  "light",
  "dark"
);

/*
:root {
    --pp-breakpoint-xs: 0;
    --pp-breakpoint-sm: 576px;
    --pp-breakpoint-md: 768px;
    --pp-breakpoint-lg: 992px;
    --pp-breakpoint-xl: 1200px;
    --pp-breakpoint-xxl: 1400px;
}
*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 780px,
  lg: 1024px,
  xl: 1366px,
  xxl: 1600px,
  xxxl: 1920px,
);
