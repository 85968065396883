$prefix: "pp-";

//COLORS
$gray: rgba(45, 57, 71, 0.54);
$black: #2d3947;
$body-bg: transparent;
$enable-rfs: false;
$enable-cssgrid: true;
$font-family-base: Circe;
$headings-margin-bottom: 0;
$headings-line-height: normal;
$transition-collapse: height 0.25s ease;
$transition-base: all 0.25s ease-in-out;
$url-content-getimage: $api-url + "/content/getimage";

:root {
  --#{$prefix}Circe-Light: Circe-Light;
  --#{$prefix}Circe-Regular: Circe;
  --#{$prefix}Circe-Bold: Circe-Bold;
  --#{$prefix}Circe-ExtraBold: Circe-ExtraBold;

  // --#{$prefix}box-shadow: 0 0.2rem 0.3rem rgba(45, 57, 71, 0.15) !important;
}
